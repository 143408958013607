/* -----------------------------------------------------------------------------

  FONTS
  
----------------------------------------------------------------------------- */
/*@font-face {*/
  /*font-family: 'Oxygen';*/
  /*font-style: normal;*/
  /*font-weight: 300;*/
  /*src: url("../fonts_menu/oxygen/oxygen-v5-latin-300.eot");*/
  /*src: local("Oxygen Light"), local("Oxygen-Light"), url("../fonts_menu/oxygen/oxygen-v5-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts_menu/oxygen/oxygen-v5-latin-300.woff2") format("woff2"), url("../fonts_menu/oxygen/oxygen-v5-latin-300.woff") format("woff"), url("../fonts_menu/oxygen/oxygen-v5-latin-300.ttf") format("truetype"), url("../fonts_menu/oxygen/oxygen-v5-latin-300.svg#Oxygen") format("svg");*/
/*}*/
/*@font-face {*/
  /*font-family: 'Oxygen';*/
  /*font-style: normal;*/
  /*font-weight: 400;*/
  /*src: url("../fonts_menu/oxygen/oxygen-v5-latin-regular.eot");*/
  /*src: local("Oxygen"), local("Oxygen-Regular"), url("../fonts_menu/oxygen/oxygen-v5-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts_menu/oxygen/oxygen-v5-latin-regular.woff2") format("woff2"), url("../fonts_menu/oxygen/oxygen-v5-latin-regular.woff") format("woff"), url("../fonts_menu/oxygen/oxygen-v5-latin-regular.ttf") format("truetype"), url("../fonts_menu/oxygen/oxygen-v5-latin-regular.svg#Oxygen") format("svg");*/
/*}*/
/*@font-face {*/
  /*font-family: 'Oxygen';*/
  /*font-style: normal;*/
  /*font-weight: 700;*/
  /*src: url("../fonts_menu/oxygen/oxygen-v5-latin-700.eot");*/
  /*src: local("Oxygen Bold"), local("Oxygen-Bold"), url("../fonts_menu/oxygen/oxygen-v5-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts_menu/oxygen/oxygen-v5-latin-700.woff2") format("woff2"), url("../fonts_menu/oxygen/oxygen-v5-latin-700.woff") format("woff"), url("../fonts_menu/oxygen/oxygen-v5-latin-700.ttf") format("truetype"), url("../fonts_menu/oxygen/oxygen-v5-latin-700.svg#Oxygen") format("svg");*/
/*}*/
/* -----------------------------------------------------------------------------

  BOX SIZING RESET
  
----------------------------------------------------------------------------- */
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

/* -----------------------------------------------------------------------------

  ROOT ELEMENTS
  
----------------------------------------------------------------------------- 
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  color: #6d6d6d;
  background-color: #fff;
  font-family: "Oxygen", Helvetica, sans-serif;
  font-size: 14px;
}

/* -----------------------------------------------------------------------------

  HEADING ELEMENTS
  
----------------------------------------------------------------------------- 
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #3b3b3b;
  font-weight: 700;
  line-height: 1.2;
}

/* -----------------------------------------------------------------------------

  TEXT ELEMENTS
  
----------------------------------------------------------------------------- 
a {
  color: #28aadc;
  text-decoration: none;
}

a:hover {
  color: #00648c;
}

b,
strong {
  font-weight: 700;
}

i,
em {
  font-style: italic;
}

/* -----------------------------------------------------------------------------

  MEDIA ELEMENTS
  
----------------------------------------------------------------------------- 
img {
  max-width: 100%;
  height: auto;
}

/* -----------------------------------------------------------------------------

  WRAPPER OBJECT
  
----------------------------------------------------------------------------- */
.o-wrapper {
  -webkit-transition: -webkit-transform 0.3s;
          transition: transform 0.3s;
}

/* -----------------------------------------------------------------------------

  CONTAINER OBJECTS
  
----------------------------------------------------------------------------- */
.o-container {
  margin: 0 auto;
  padding: 0 12px;
  max-width: 960px;
}

@media all and (min-width: 480px) {
  .o-container {
    padding: 0 24px;
  }
}
@media all and (min-width: 720px) {
  .o-container {
    padding: 0 48px;
  }
}
/* -----------------------------------------------------------------------------

  HEADER OBJECT
  
----------------------------------------------------------------------------- */
.o-header {
  margin: 0;
  padding: 0;
}

.o-header-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #67b5d1;
}

.o-header-nav__link {
  padding: 12px;
  color: #fff;
}

.o-header-nav__link:hover {
  color: #fff;
  background-color: #3184a1;
}

.o-header__title {
  margin: 24px;
  padding: 0;
  color: #818181;
  font-size: 28px;
  font-weight: 300;
  text-align: center;
}

@media all and (min-width: 480px) {
  .o-header__title {
    margin: 36px;
    font-size: 42px;
  }
}
/* -----------------------------------------------------------------------------

  MAIN OBJECT
  
----------------------------------------------------------------------------- */
.o-main {
  /**/
}

/* -----------------------------------------------------------------------------

  SUB-NAVIGATION OBJECT
  
----------------------------------------------------------------------------- */
.o-sub-nav {
  margin: 0 0 24px 0;
  padding: 0;
}

.o-sub-nav__items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.o-sub-nav__item {
  margin: 0;
  padding: 4px;
}

.o-sub-nav__link {
  display: block;
  margin: 0;
  padding: 4px 24px;
  border: solid 2px #67b5d1;
}

.o-sub-nav__link:hover,
.o-sub-nav__item.active .o-sub-nav__link {
  color: #fff;
  background-color: #67b5d1;
}

@media all and (min-width: 720px) {
  .o-sub-nav {
    margin: 0 0 48px 0;
  }
}
/* -----------------------------------------------------------------------------

  FOOTER OBJECT
  
----------------------------------------------------------------------------- */
.o-footer {
  padding: 12px 0;
  text-align: center;
}

@media all and (min-width: 480px) {
  .o-footer {
    padding: 24px 0;
  }
}
@media all and (min-width: 720px) {
  .o-footer {
    padding: 48px 0;
  }
}
/* -----------------------------------------------------------------------------

  SLIDE AND PUSH MENUS COMPONENT
  
----------------------------------------------------------------------------- */
/**
 * Menu overview.
 */
.c-menu {
  position: fixed;
  z-index: 200;
  /* background-color: #67b5d1; */
  -webkit-transition: -webkit-transform 0.3s;
          transition: transform 0.3s;
}

.c-menu__items {
  list-style: none;
  margin: 0;
  padding: 0;
}

/**
 * Left and right menus
 *
 * Slide and push menus coming in from the left and right inherit a lot of
 * common styles. We'll start each of them off by doing up the common styles
 * for each version, followed by individual styles.
 *
 * The various versions are governed by modifier classes.
 */
/**
 * Common modifiers for left/right menus.
 */
.c-menu--slide-left,
.c-menu--slide-right,
.c-menu--push-left,
.c-menu--push-right {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
@media all and (min-width: 320px) {
  .c-menu--slide-left,
  .c-menu--slide-right,
  .c-menu--push-left,
  .c-menu--push-right {
    width: 261px;
    padding: 20px;
  }
}

.c-menu--slide-left .c-menu__item,
.c-menu--slide-right .c-menu__item,
.c-menu--push-left .c-menu__item,
.c-menu--push-right .c-menu__item {
  display: block;
  text-align: center;
  border-top: solid 1px #b5dbe9;
  border-bottom: solid 1px #3184a1;
}
.c-menu--slide-left .c-menu__item:first-child,
.c-menu--slide-right .c-menu__item:first-child,
.c-menu--push-left .c-menu__item:first-child,
.c-menu--push-right .c-menu__item:first-child {
  border-top: none;
}
.c-menu--slide-left .c-menu__item:last-child,
.c-menu--slide-right .c-menu__item:last-child,
.c-menu--push-left .c-menu__item:last-child,
.c-menu--push-right .c-menu__item:last-child {
  border-bottom: none;
}

.c-menu--slide-left .c-menu__link,
.c-menu--slide-right .c-menu__link,
.c-menu--push-left .c-menu__link,
.c-menu--push-right .c-menu__link {
  display: block;
  padding: 12px 24px;
  color: #fff;
}

.c-menu--slide-left .c-menu__close,
.c-menu--slide-right .c-menu__close,
.c-menu--push-left .c-menu__close,
.c-menu--push-right .c-menu__close {
  display: block;
  padding: 12px 24px;
  width: 100%;
}

/**
 * Slide/Push Menu Left.
 */
.c-menu--slide-left,
.c-menu--push-left {
  top: 0;
  left: 0;
  -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
          transform: translateX(-100%);
}
@media all and (min-width: 320px) {
  .c-menu--slide-left,
  .c-menu--push-left {
    -webkit-transform: translateX(-300px);
        -ms-transform: translateX(-300px);
            transform: translateX(-300px);
  }
}

.c-menu--slide-left.is-active,
.c-menu--push-left.is-active {
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
}

/**
 * Slide/Push Menu Right.
 */
.c-menu--slide-right,
.c-menu--push-right {
  top: 0;
  right: 0;
  -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
          transform: translateX(100%);
}
@media all and (min-width: 320px) {
  .c-menu--slide-right,
  .c-menu--push-right {
    -webkit-transform: translateX(300px);
        -ms-transform: translateX(300px);
            transform: translateX(300px);
  }
}

.c-menu--slide-right.is-active,
.c-menu--push-right.is-active {
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
}

/**
 * Top and bottom menus
 *
 * Slide and push menus coming in from the top and bottom inherit a lot of
 * common styles. We'll start each of them off by doing up the common styles
 * for each version, followed by individual styles.
 *
 * The various versions are governed by modifier classes.

/**
 * Common modifiers for top/bottom menus
 */
.c-menu--slide-top,
.c-menu--slide-bottom,
.c-menu--push-top,
.c-menu--push-bottom {
  vertical-align: middle;
  width: 100%;
  height: 60px;
  text-align: center;
  overflow-x: scroll;
}

.c-menu--slide-top .c-menu__items,
.c-menu--slide-bottom .c-menu__items,
.c-menu--push-top .c-menu__items,
.c-menu--push-bottom .c-menu__items {
  display: inline-block;
  text-align: center;
}

.c-menu--slide-top .c-menu__item,
.c-menu--slide-bottom .c-menu__item,
.c-menu--push-top .c-menu__item,
.c-menu--push-bottom .c-menu__item {
  display: inline-block;
  line-height: 60px;
}

.c-menu--slide-top .c-menu__link,
.c-menu--slide-bottom .c-menu__link,
.c-menu--push-top .c-menu__link,
.c-menu--push-bottom .c-menu__link {
  display: block;
  padding: 0 4px;
  color: #fff;
}

.c-menu--slide-top .c-menu__close,
.c-menu--slide-bottom .c-menu__close,
.c-menu--push-top .c-menu__close,
.c-menu--push-bottom .c-menu__close {
  display: inline-block;
  margin-right: 12px;
  padding: 0 24px;
  height: 60px;
  line-height: 60px;
}

/**
 * Slide/Push Menu Top.
 */
.c-menu--slide-top,
.c-menu--push-top {
  top: 0;
  left: 0;
  -webkit-transform: translateY(-60px);
      -ms-transform: translateY(-60px);
          transform: translateY(-60px);
}

.c-menu--slide-top.is-active,
.c-menu--push-top.is-active {
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}

/**
 * Slide/Push Menu Bottom.
 */
.c-menu--slide-bottom,
.c-menu--push-bottom {
  bottom: 0;
  left: 0;
  -webkit-transform: translateY(60px);
      -ms-transform: translateY(60px);
          transform: translateY(60px);
}

.c-menu--slide-bottom.is-active,
.c-menu--push-bottom.is-active {
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}

/**
 * Wrapper states.
 *
 * Various wrapper states occur depending on if a menu is pushing into view, in
 * which case, the wrapper has to be pushed by the respective distance.
 */
.o-wrapper.has-push-left {
  -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
          transform: translateX(100%);

}
@media all and (min-width: 320px) {
  .o-wrapper.has-push-left {
    -webkit-transform: translateX(300px);
        -ms-transform: translateX(300px);
            transform: translateX(300px);

  }
}

.o-wrapper.has-push-right {
  -webkit-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
          transform: translateX(-100%);

}
@media all and (min-width: 320px) {
  .o-wrapper.has-push-right {
    -webkit-transform: translateX(-300px);
        -ms-transform: translateX(-300px);
            transform: translateX(-300px);
  }
}

.o-wrapper.has-push-top {
  -webkit-transform: translateY(60px);
      -ms-transform: translateY(60px);
          transform: translateY(60px);
}

.o-wrapper.has-push-bottom {
  -webkit-transform: translateY(-60px);
      -ms-transform: translateY(-60px);
          transform: translateY(-60px);
}

/**
 * Body states.
 *
 * When a menu is active, we want to hide the overflows on the body to prevent
 * awkward document scrolling.
 */
body.has-active-menu {
  overflow: hidden;
}

/**
 * Close button resets.
 */
.c-menu__close {
  color: #fff;
  background-color: #000;
  font-size: 14px;
  border: none;
  box-shadow: none;
  border-radius: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.c-menu__close:focus {
  outline: none;
}

/* -----------------------------------------------------------------------------

  MASK COMPONENT
  
----------------------------------------------------------------------------- */
.c-mask {
  position: fixed;
  z-index: 102;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 0;
  height: 0;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.3s, width 0s 0.3s, height 0s 0.3s;
          transition: opacity 0.3s, width 0s 0.3s, height 0s 0.3s;
}

.c-mask.is-active {
  width: 100%;
  height: 100%;
  opacity: 0.7;
  -webkit-transition: opacity 0.3s;
          transition: opacity 0.3s;
}

/* -----------------------------------------------------------------------------

  BUTTONS
  
----------------------------------------------------------------------------- */
.c-buttons {
  margin-bottom: 48px;
  text-align: center;
}

.c-button {
  display: inline-block;
  margin: 4px;
  padding: 12px 24px;
  color: #67b5d1;
  background: none;
  font-size: 14px;
  border: solid 2px #67b5d1;
  box-shadow: none;
  border-radius: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.c-button:focus {
  outline: none;
}

.c-button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

/* -----------------------------------------------------------------------------

  CARBON AD COMPONENT
  
----------------------------------------------------------------------------- */
#carbonads {
  position: fixed;
  bottom: 12px;
  right: 12px;
  z-index: 1000;
  padding: 24px 12px 12px 12px;
  width: 154px;
  background-color: #fff;
  line-height: 1.1;
  border: solid 1px #e7e7e7;
}

.carbon-wrap {
  display: block;
  margin: 0 0 4px 0;
}

.carbon-img {
  display: block;
  margin: 0 0 4px 0;
  padding: 0;
  width: 130px;
  height: 100px;
}

.carbon-text {
  color: #818181;
  font-size: 12px;
}

.carbon-poweredby {
  font-size: 10px;
  font-style: italic;
}

.carbonad__close {
  display: block;
  position: absolute;
  top: 0;
  left: 12px;
  height: 24px;
  font-size: 11px;
  line-height: 24px;
}

@media all and (max-width: 660px) {
  #carbonads {
    display: none;
  }
}
