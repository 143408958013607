
/*------------------------------------------------------- logo ------------------------------------------------------------------*/
@media (min-width: 1200px) {
    .logo {
        padding-left: 40px;
    }
}
@media (min-width: 1320px) {
    .logo {
        padding-left: 0;
    }
}
.cabecera-login img {
    width: 123px;
}
/*------------------------------------------------------- color rojo ------------------------------------------------------------------*/
.text-red,
.cont-menu ul li a i,
.cont-menu ul li a.rojo,
.telf-xs,
.telf-xs:hover,
.telf-xs:focus,
.PQFoxize h3,
.unete li,
.modulo-curso .lugar-fecha,
.circle-info,
.circle-info-half,
.calendar:before,
.direc:before,
.ubicacion,
.mobile,
.disc_red li,
.datos_curso .profesor .nombre,
.titulo_suscribete,
.nuestros_principios .num,
.update .numero span {
    color: #DD1624;
}
.btn-red,
.btn-red:active,
.btn-red:focus,
.btn-red:hover,
.carrito-xs span,
.notificacion-xs span,
.newsletter-redes-empresa .input-group-addon,
.proximamente span,
.sold_aut span,
.finalizado span,
.btn_cursos .active
.btn_cursos .active:hover,
.head_sidebar > div span,
.apuntarme > div span,
.descarga_resumen .input-group-addon,
.f-rojo {
    background-color: #DD1624;
}
.btn-red,
.btn-red:active,
.btn-red:focus,
.btn-red:hover,
.form-control:focus,
.buscador .form-control:focus,
.cont-menu .form-control:focus,
.num,
.nuestros_principios .num,
.descarga_resumen .input-group-addon,
.update .numero span,
.menu_suscripcion .active a,
.menu_sub .active a,
.menu_suscripcion a:hover,
.menu_sub a:hover {
    /*border-color: #DD1624*/;
}
.img_curso {
    display: block;
    border-bottom: solid 43px #4e4e4e;
}
@media (min-width: 768px) {
    .telf,
    .mi_cuenta i,
    .desple-carrito .docente,
    .desple-carrito .fecha {
        color: #DD1624;
    }
    .carrito > span,
    .notificacion > span {
        background-color: #DD1624;
    }
}
/*------------------------------------------------------- boton negro a gris ------------------------------------------------------------------*/
.btn-black,
.btn-carrito {
    color: #fff !important;
    background-color: #4E4E4E;
    border-color: #4E4E4E;
}
.btn-black:active,
.btn-black:focus,
.btn-black:hover,
.btn-carrito:active,
.btn-carrito:focus,
.btn-carrito:hover {
    color: #fff !important;
    background-color: #4E4E4E;
    border-color: #4E4E4E;
}
/*------------------------------------------------------- footer ------------------------------------------------------------------*/
.metodos-pago {
    float: inherit;
    margin-top: 15px;
}
.condiciones_newsletter {
    position: relative;
    margin-left: 0;
    margin-top: 10px;
}
@media (min-width: 992px) {
    .colum-redes {
        margin-top: 105px;
    }
    .border-left2 {
        border-left: solid 1px #f2f4f4;
    }
    .cont-redes {
        margin-bottom: 20px;
    }
}
/*------------------------------------------------------- home sin login ------------------------------------------------------------------*/
#owl-slider-home .owl-wrapper .owl-item:nth-child(1) {
    background-image: url(../img/slider_home.jpg);
}
#owl-slider-home .item {
    text-align: left;
    color: inherit;
}
.centra {
    padding: 0 25px;
}
@media (min-width: 768px) {
    .centra {
        padding: 25px 0;
        width: 720px;
        margin: 0 auto;
    }
}
@media (min-width: 992px) {
    .centra {
        width: 940px;
    }
}
@media (min-width: 1200px) {
    .centra {
        width: 1140px;
    }
}
.titu-slider {
    color: #DD1624;
}
@media (min-width: 992px) {
    .sub-titu-slider {
        width: 50%;
    }
    .btn-slider {
        margin: 15px 0;
    }

}


.btn-slider {
    background-color: #DD1624;
    border: solid 1px #DD1624;
    color: #FFFFFF;
}
.btn-slider:hover {
    background-color: #DD1624;
}
.num {
    width: 25px;
    height: 47px;
    margin: 0 auto 20px auto;
    /*border-bottom: none;*/
    text-align: center;
    font-family: 'robotoblack', Arial, Helvetica, sans-serif;
    font-size: 42px;
    line-height: 42px;
    /*color: #fff;
    background-color: #DD1624;
    border-bottom-right-radius: 26px;;*/
}
.tematicas ul {
    font-family: 'robotoregular', Arial, Helvetica, sans-serif;
    text-align: center!important;
}
.tematicas ul i {
    color: #fff;
}
.tematicas ul li span {
    background-color: #EF3724;
    border-radius:45;
}
@media (min-width: 992px) {
    .tematicas ul li h3 {
        padding: 0;
    }
}
.PQFoxize {
    color: inherit;
    background-color: #fff;
}
.line-right {
    background-image: url(../img/linea_macmillan.gif);
}
#owl-slider-testimoniales .owl-wrapper-outer {
    background-image: url(../img/Pic_Users_Home_Color.jpg);
}
#owl-slider-testimoniales .item .nombre  {
    display: inline-block;
    margin-top: 30px;
}
#owl-slider-testimoniales .item {
    padding-top: 106px;
}
#owl-slider-testimoniales .item .txt {
    width: 570px;
    color: #fff;
    padding: 35px 35px 10px 35px;
    background-color: #DD1624;
    border-radius: 0;
    border-top-left-radius: 50px;
}
/*
#owl-slider-testimoniales .item .txt:after  {
	content: " ";
	width: 100px;
	height: 107px;
	bottom: -106px;
	left: 0;
	position: absolute;
	border-width: 0;
	margin-left: 0;
	background-image: url(../img/fondo_item.svg);
	background-repeat: no-repeat;
	background-size: cover;
}*/
.testimoniales .slider .owl-controls {
    margin-top: 20px;
    margin-bottom: 30px;
}
.testimoniales .slider .owl-controls .owl-page.active span {
    border: solid 1px #666;
}
.testimoniales .slider .owl-controls .owl-page.active span:before {
    background-color: #666;
}

@media (min-width: 768px) {
    .num {
        float: none;
    }
    .empiezo>.edit {
        width: initial;
    }
}

@media (min-width: 992px) {
    .num {
        margin: 0 0 20px 0;
        float: left;
    }
    .empiezo>.edit {
        width: 215px;
    }
}

@media (min-width: 1200px) {
    .empiezo>.edit {
        width: 275px;
    }
}

/*------------------------------------------------------- home con login ------------------------------------------------------------------*/
.slider_home_login .slider .owl-controls {
    margin-top: 20px;
    margin-bottom: 0;
}
.slider_home_login .slider .owl-controls .owl-page.active span {
    border: solid 1px #666;
}
.slider_home_login .slider .owl-controls .owl-page.active span:before {
    background-color: #666;
}
@media (min-width: 700px) {
    #owl-slider-home-login .item {
        text-align: left;
        padding: 30px;
    }
}


@media (max-width: 700px) {
    #owl-slider-home-login .item {

        padding: 0;
    }
}
.centrado_update {
    width: 330px;
    text-align: center;
}
@media (min-width: 992px) {
    .menu_sub li {
        width: 25%;
    }
    .menu_sub li a {
        min-height: 35px;
    }
}
.selector {
    margin-bottom: 40px;
}
.selector > li {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
}
.selector .bootstrap-select > .dropdown-toggle {
    background-color: #fff;
}
.img_curso img {
    width: 100%;
}
.info-curso {
    -webkit-border-top-right-radius: 30px;
    -moz-border-radius-top-right: 30px;
    border-top-right-radius: 30px;
    margin-top: -30px;
}
.modulo-curso .lugar-fecha {
    padding-left: 0;
}
.modulo-curso .precio img,
.modulo-programa .precio img {
    margin: 0 2px;
}
/*------------------------------------------------------- login ------------------------------------------------------------------*/
.soy-usuario,
.nuevo {
    text-align: center;
    min-height: 400px;
}
.soy-usuario h1 {
    text-align: center;
}
.soy-nuevo {
    margin: 0;
}
@media (min-width: 768px) {
    .unete li {
        padding-bottom: 8px;
    }
    .unete {
        margin-bottom: 11px;
    }
}
@media (min-width: 1200px) {
    .nuevo h1 {
        text-align: center;
    }
}

/*------------------------------------------------------- registro ------------------------------------------------------------------*/
.recupera {
    text-align: center;
}

/*------------------------------------------------------- precios ------------------------------------------------------------------*/
.planes_precio .f-gris {
    background-color: #4E4E4E;
}
.cuerpo_lista_precio {
    min-height: inherit;
}
@media (min-width: 768px) {
    .cuerpo_lista_precio {
        min-height: 290px;
    }
}
.incluyen_planes_precio {
    text-align: center;
    padding-bottom: 30px;
    overflow: hidden;
}
.incluyen_planes_precio li {
    font-size: 15px;
    text-align: center;
    margin: 0 15px 20px 15px;
    border-bottom: solid 1px #f2f4f4;
    min-height: 200px;
}
.incluyen_planes_precio li:nth-child(4) {
    border: none;
}
.incluyen_planes_precio img {
    margin-bottom: 25px;
}
.inf {
    text-align: center;
}
@media (min-width: 768px) {
    .incluyen_planes_precio li {
        margin: 0 0 20px 0;
        padding: 0 15px;
        float: left;
        width: 50%;
        border: none;
    }
    .incluyen_planes_precio li:nth-child(2n-1) {
        border-right: solid 1px #f2f4f4;
    }
    .incluyen_planes_precio li h4,
    .incluyen_planes_precio li p {
        width: 80%;
        margin: 0 auto;
        text-align: left;
    }
    .inf {
        width: 50%;
        float: right;
    }
}
@media (min-width: 992px) {
    .incluyen_planes_precio li,
    .inf {
        width: 25%;
    }
    .incluyen_planes_precio li {
        border-right: solid 1px #f2f4f4;
    }
    .incluyen_planes_precio li h4,
    .incluyen_planes_precio li p {
        width: 90%;
    }
}
@media (min-width: 1200px) {
    .incluyen_planes_precio li {
        margin-bottom: 20px;
    }
}

@media (min-width: 768px) {
    .cont_search input {
        height: 40px;
        line-height: 40px;
    }
    .cont_search {
        width: 412px;
        margin: 10px 0 0 0;
    }
}
@media (min-width: 992px) {
    .busq_biblioteca p {
        font-size: 14px;
        padding-right: 10px;
    }
    .cont_search {
        width: 590px;
    }
}
@media (min-width: 1200px) {
    .cont_search {
        float: right;
        width: 227px;
        margin: 0;
    }
}

.cont_search input {
    width: 100%;
    border-radius: 5px;
    color: #2B2C33;
    border: solid 1px #dfe1e1;
    height: 30px;
    line-height: 30px;
    background-image: url(../img/zoom.svg);
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 8px center;
    padding-left: 30px;
    font-family: 'robotolight', Arial, Helvetica, sans-serif;
}


/*------------------------------------------------------- biblioteca ------------------------------------------------------------------*/

.cont_search input {
    width: 100%;
    border-radius: 5px;
    color: #2B2C33;
    border: solid 1px #dfe1e1;
    height: 30px;
    line-height: 30px;
    background-image: url(../img/zoom.svg);
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 8px center;
    padding-left: 30px;
    font-family: 'robotolight', Arial, Helvetica, sans-serif;
}
.cont_search ::-webkit-input-placeholder { font-family: 'robotolight', Arial, Helvetica, sans-serif; color: #2B2C33; font-size: 14px; opacity: 1;}
.cont_search ::-moz-placeholder { font-family: 'robotolight', Arial, Helvetica, sans-serif; color: #2B2C33; font-size: 14px; opacity: 1;}
.cont_search :-ms-input-placeholder { font-family: 'robotolight', Arial, Helvetica, sans-serif; color: #2B2C33; font-size: 14px; opacity: 1;}
.cont_search input:-moz-placeholder { font-family: 'robotolight', Arial, Helvetica, sans-serif; color: #2B2C33; font-size: 14px; opacity: 1;}

@media (min-width: 768px) {
    .cont_search input {
        height: 40px;
        line-height: 40px;
    }
    .cont_search {
        width: 412px;
        margin: 10px 0 0 0;
    }
}
@media (min-width: 992px) {
    .busq_biblioteca p {
        font-size: 14px;
        padding-right: 10px;
    }
    .cont_search {
        width: 590px;
    }
}
@media (min-width: 1200px) {
    .cont_search {
        float: right;
        width: 227px;
        margin: 0;
    }
}
.biblio  article {
    background-color: #fff;
}
.biblio  article header {
    text-align: center;
    text-transform: uppercase;
    padding: 22px 22px 0 22px;
    font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
    font-size: 16px;
}
.biblio  article header hr {
    margin: 0 0 -12px 0;
    border: 0;
    border-top: 1px solid #eee;
}
.biblio  article header p {
    background-color: #fff;
    padding: 0 15px;
    display: inline-block;
}
.biblio  article figure {
    text-align: center;
    height: 160px;
    line-height: 160px;
    margin: 0 0 20px 0;
}
.biblio  article figure img {
    max-height: 100%;
}
.biblio article footer {
    background-color: #6D7076;
    height: 120px;
    color: #fff;
    font-size: 15px;
    text-align: center;
}
.biblio article footer .txt {
    font-family: 'robotomedium', Arial, Helvetica, sans-serif;
    height: 85px;
    padding: 20px 20px 0 20px;
}
.biblio article footer .autor {
    font-family: 'robotolight', Arial, Helvetica, sans-serif;
}
/*@media (min-width: 992px) {
	.biblio_cont_b_columna  {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		    flex-wrap: wrap;
	}
	.biblio  .b_columna {
		margin-right: 30px;
		width: calc((100% - 30px) / 2);
	}
	.biblio  .b_columna:nth-child(2n) {
		margin-right: 0;
	}
}*/
/*@media (min-width: 1200px) {
	.biblio  .b_columna {
		width: calc((100% - 60px) / 3);
	}
	.biblio  .b_columna:nth-child(2n) {
		margin-right: 30px;
	}
	.biblio  .b_columna:nth-child(3n) {
		margin-right: 0;
	}
}*/
.ficha_biblio {
    padding: 20px 0 0 0;
    overflow: hidden;
}
.ficha_biblio .colum-izq,
.ficha_biblio .colum-der {
    padding: 0 20px 30px 20px;
}
.ficha_biblio figure {
    text-align: center;
    display: flex;
    justify-content: center;
}
.ficha_biblio .autor {
    text-align: center;
    font-family: 'robotomedium', Arial, Helvetica, sans-serif;
    font-size: 13px;

    padding: 22px 0 10px 0;
}
.ficha_biblio .autor span {
    background-image: url(../img/cesta_red.svg);
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 19px;
    padding-left: 26px;
    line-height: 26px;
    display: inline-block;
}
.ficha_biblio .tags {
    margin-top: -1px;
    font-size: 13px;
}
.ficha_biblio .tags span {
    background-position: 0 16px;
    background-repeat: no-repeat;
    background-size: 19px;
    padding: 15px 0 15px 20px;
    line-height: 21px;
}
.ficha_biblio .tags span i {
    margin-right: 5px;
}
.ficha_biblio .tags span.carpeta_red {
    padding-left: 25px;
}
.ficha_biblio .tags .lapiz_red {
    background-image: url(../img/lapiz_red.svg);
}
.ficha_biblio .tags .marca_red {
    background-image: url(../img/marca_red.svg);
}
.carpeta_red {
    background-image: url(../img/carpeta_red.svg);
}
.ficha_biblio .cont_btn {
    overflow: hidden;
    text-align: center;
    padding: 30px 0 10px 0;
}
.ficha_biblio .cont_btn .btn,
.check_add_favoritos_biblio {
    width: 100%;
    max-width: 235px;
}
.check_add_favoritos_biblio {
    margin: 0 auto 20px auto;
}
.check_add_favoritos_biblio input[type=checkbox] {
    display: none;
}
.check_add_favoritos_biblio label {
    font-weight: normal;
    font-size: 14px;
    color: #000000;
    background-color: #182A55;
    position: relative;
    width: 100%;
    display: table;
    border-radius: 4px;
    padding: 13px 8px 13px 45px;
    cursor: pointer;
    margin: 0;
    font-family: 'robotomedium', Arial, Helvetica, sans-serif;
}
.check_add_favoritos_biblio label p {
    display: table-cell;
    vertical-align: middle;
}
.check_add_favoritos_biblio label:before {
    position: absolute;
    content: "";
    display: inline-block;
    background-image: url(../img/favourite_white.svg);
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    left: 15px;
    top: 50%;
    margin-top: -12px;
}
.check_add_favoritos_biblio input[type=checkbox]:checked + label:before {
    background-image: url(../img/favourite.svg);
    background-repeat: no-repeat;
    left: 0;
    top: 50%;
    left: 15px;
    margin-top: -12px;
}
@media (min-width: 1200px) {
    .ficha_biblio .colum-izq,
    .ficha_biblio .colum-der {
        float: left;
    }
    .ficha_biblio .colum-izq {
        width: 210px;
    }
    .ficha_biblio .colum-der {
        width: 637px;
        padding: 0 20px 30px 0;
    }
    .check_add_favoritos_biblio,
    .ficha_biblio .cont_btn .btn {
        float: left;
        margin: 0 20px 0 0;
    }
    .border-right {
        border-right: solid 1px #ccc;
    }
}




/*------------------------------------------------------- competencias ------------------------------------------------------------------*/
.subtematica {
    padding-top: 20px;
}
.subtematica a {
    text-decoration: underline;
}
.f-gris10 {
    background-color: #4E4E4E;
}
.hacer_test {
    text-align: center;
    overflow: hidden;
}
@media (min-width: 768px)  {
    .hacer_test {
        text-align: left;
    }
    .hacer_test p {
        display: inline;
        line-height: 44px;
    }
    .hacer_test a {
        float: right;
        margin-right: 40px;
    }
}


/*------------------------------------------------------- mi cuenta cusos ------------------------------------------------------------------*/
.mi_cuenta_section_3,
.mi_cuenta_section_3 .valorar .star {
    background-color: #4E4E4E;
}


/*------------------------------------------------------- mi cuenta programas ------------------------------------------------------------------*/
.mi_cuenta_section_3_2,
.mi_cuenta_section_3_2 .valorar .star {
    background-color: #4E4E4E;
}


/*------------------------------------------------------- curso online ------------------------------------------------------------------*/
.apuntarme,
.precio_curso span,
.pertenece_programa {
    background-color: #4E4E4E;
}
.dc li:nth-child(5) {
    width: 100%;
    border-right: none;
}
@media (min-width: 992px) {
    .centra_verti {
        height: 90px;
    }
    .dt_left {
        width: 100%;
    }
    .dc li,
    .dc li:nth-child(5) {
        width: 20%;
    }
    .dc li:nth-child(3) {
        border-right: solid 1px #DFE1E1;
    }
    .df li {
        float: left;
    }
    .df li:nth-child(1) {
        width: 60%;
        border-right: solid 1px #DFE1E1;
    }
    .df li:nth-child(2) {
        width: 40%;
        text-align: left;
        padding-left: 30px;
    }
}
.no_border {
    border: none;
}
.video img {
    width: 100%;
}
.m_adicional {
    /*max-width: 570px;
    margin: 00px auto;*/
}
.m_adicional li {
    padding: 12px;
    border-top: solid 1px #DFE1E1;
}
.m_adicional li:first-child {
    border: none;
}
.m_adicional li span {
    display: block;
}
@media (min-width: 768px)  {
    .m_adicional li span {
        float: right;
    }
}
.apuntarme .star {
    background-color: #4E4E4E;
}
.lecciones li {
    border-top: solid 1px #DFE1E1;
}
.lecciones li:first-child {
    border: none;
}

.leccion {
    padding-left: 20px;
    padding-right: 20px;
}
.titu {
    font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
    font-size: 18px;
    margin: 20px 0;
    cursor: pointer;
}
.cont-leccion {
    display: none;
}
.cont-leccion.activo {display: block;}


.width400 {
    max-width: 400px;
    margin: 0 auto;
}

/*------------------------------------------------------- 404 ------------------------------------------------------------------*/
.text_404 p:nth-child(1) {
    color: #333;
}
.f_blanco {
    background-color: #fff;
}

/*------------------------------------------------------- proceso compra test ------------------------------------------------------------------*/

.contenedor_blanco {
    font-family: 'robotolight', Arial, Helvetica, sans-serif;
    text-align: center;
    padding: 45px 20px 50px 20px;
    background-color: #fff;
    overflow: hidden;
}
.banner_bono {
    margin-bottom: 45px;
}
@media (min-width: 768px)  {
    .banners_bonos {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .banner_bono {
        width: 31%;
    }

}
.titulo_bono {
    font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
    font-size: 32px;
    line-height: 36px;
    background-color: #4E4E4E;
    color: #fff;
    border-radius: 5px 5px 0 0;
    padding: 55px 0 30px 0;
}
.cuerpo_bono {
    font-family: 'robotoregular', Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #6C7076;
    border-left: solid 1px #D9D9D9;
    border-right: solid 1px #D9D9D9;
    border-bottom: solid 1px #D9D9D9;
}
.cuerpo_bono ul li {
    border-bottom: solid 1px #F2F4F4;
    height: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.cuerpo_bono p {
    text-align: left;
    width: 160px;
    margin: 0;
    line-height: 18px;
    padding: 0 0 0 35px;
    background-image: url(../img/marca.svg);
    background-repeat: no-repeat;
    background-position: 12px 4px;
}
.cuerpo_bono .btn {
    width: 100%;
    max-width: 170px;
    margin: 17px 0;
}
.ancho_p {
    width: 100%;
    max-width: 530px;
    margin: 0 auto;
}
.btn_nota .btn {
    width: 100%;
    max-width: 225px;
    margin: 10px auto 0 auto;
    display: block;
}
.btn_nota .btn:nth-child(1) {
    margin-top: 50px;
}
@media (min-width: 768px)  {
    .btn_nota .btn {
        display: inline-block;
    }
    .btn_nota .btn:nth-child(1),
    .btn_nota .btn:nth-child(2) {
        margin: 50px 25px 20px 25px;
    }
}


/*------------------------------------------------------- sobre macmillan ------------------------------------------------------------------*/

.equipo_profesor {
    height: 395px;
    position: relative;
}
.equipo-redes {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 25px;
}
.que_nos_une {
    width: 95%;
    margin: 0 auto;
    text-align: left;
    font-size: 16px;
    margin-top: 30px;
}
.que_nos_une span {
    font-family: 'robotolight', Arial, Helvetica, sans-serif;
}
.que_nos_une li {
    margin-bottom: 20px;
}
.que_nos_une strong {
    font-family: 'robotomedium', Arial, Helvetica, sans-serif;
    font-weight: normal;
}
.num_2 {
    width: 25px;
    height: 40px;
    margin: 0 auto 15px auto;
    border-bottom: 3px solid #EF3724;
    text-align: center;
    font-family: 'robotobold', Arial, Helvetica, sans-serif;
    font-size: 32px;
    line-height: 32px;
    color: #EF3724;
}
.pq {
    font-size: 15px;
    text-align: center;
    padding-bottom: 30px;
    padding-top: 20px;
    border-bottom: solid 1px #F2F4F4;
}
@media (min-width: 992px)  {
    .num_2 {
        margin: 0 0 15px 15px;
    }
    .pq {
        border: none;
        text-align: left;

    }
    .pq p {
        margin: 0 15px;
    }
}


/*------------------------------------------------------- gestion bono ------------------------------------------------------------------*/

.cuadrado {
    font-family: 'robotobold', Arial, Helvetica, sans-serif;
    font-size: 15px;
    background-image: url(../img/cuadrado.png);
    background-repeat: no-repeat;
    background-position: left top;
    padding-left: 60px;
}
.cuadrado p {
    margin-bottom: 5px;
}

/*Table9*/
.table_mis_bonos {
    width: 100%;
}
.table_mis_bonos td {
    font-size: 15px;
    padding: 15px 5px;
}
.table_mis_bonos tbody tr td:nth-child(3) {
    color: #6C7076;
    font-size: 14px;
}
@media (max-width: 991px) {
    .table_mis_bonos table,
    .table_mis_bonos thead,
    .table_mis_bonos tbody,
    .table_mis_bonos th,
    .table_mis_bonos td,
    .table_mis_bonos tr {
        display: block;
    }
    .table_mis_bonos thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    .table_mis_bonos td,
    .table_mis_bonos th {
        width: 100%;
    }
    .table_mis_bonos td {
        position: relative;
        padding-left: 50% !important;
    }
    .table_mis_bonos td:before {
        position: absolute;
        top: 6px;
        left: 10px;
        width: 45%;
        padding:  3px 10px 0 0;
        white-space: nowrap;
        font-size: 15px;
        font-family: 'robotoregular', Arial, Helvetica, sans-serif;
    }

    .table_mis_bonos td:nth-of-type(1):before { content: "Cursos realizados: 5/5";}
    .table_mis_bonos td:nth-of-type(2):before { content: "Fecha"; }
    .table_mis_bonos td:nth-of-type(3):before { content: "Usuario"; }

    .table_mis_bonos tbody tr:nth-child(2n+1) {
        background-color: #F2F4F4;
    }
    .table_mis_bonos .star {
        margin: 0;
    }
}
@media (min-width: 992px) {
    .table_mis_bonos tbody tr td:first-child,
    .table_mis_bonos thead tr th:first-child {
        text-align: left;
        padding-left: 15px;
    }
    .table_mis_bonos td {
        text-align: center;

    }
    .table_mis_bonos th {
        background-color: #F2F4F4;
        font-size: 15px;
        padding: 15px 5px;
        text-align: center;
    }
    .table_mis_bonos thead tr th:nth-child(1),
    .table_mis_bonos tbody tr td:nth-child(1),
    .table_mis_bonos thead tr th:nth-child(2),
    .table_mis_bonos tbody tr td:nth-child(2) {
        border-right: solid 1px #DFE1E1;
    }
    .table_mis_bonos tbody tr {
        border-top: solid 1px #DFE1E1;
    }
    .table_mis_bonos tbody tr:first-child {
        border-top: none;
    }


    .tematicas ul li {

        /*width: 17.5%;
*/	}
}

#contenido-favoritos .biblio article {
    width: initial;
    margin-right: 0;
}

.modulo-curso .img_curso
{
    max-height: 220px;
    min-height: 220px;
    overflow: hidden;
    width: auto;
    display: block;
}

.modulo-curso .img_curso img
{
    overflow-y: hidden;
    overflow-x: hidden;
}
@media (max-width: 768px) {
    .shopping_favorites_shake{
        border-bottom: solid 1px #f2f4f4;
        border-right: solid 1px #f2f4f4;
    }
}
@media (min-width: 768px) {
    .shopping_favorites_shake{
    }
}