/*
* 	Owl Carousel Owl Demo Theme 
*	v1.3.3
*/
.owl-theme .item {
    padding: 0 15px;
}

.owl-theme .owl-controls{
    margin-top: 0px;
    text-align: center;
}

/* Styling Next and Prev buttons */

.owl-theme .owl-controls .owl-buttons div{
    color: #000;
    display: inline-block;
    zoom: 1;
    *display: inline;/*IE7 life-saver */
    margin: 5px;
    padding: 3px 10px;
    font-size: 12px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background: #869791;
    filter: Alpha(Opacity=50);/*IE7 fix*/
    opacity: 0.5;
}
/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover{
    filter: Alpha(Opacity=100);/*IE7 fix*/
    opacity: 1;
    text-decoration: none;
}

/* Styling Pagination*/

.owl-theme .owl-controls .owl-page{
    display: inline-block;
    zoom: 1;
    *display: inline;/*IE7 life-saver */
}
.owl-theme .owl-controls .owl-page span {
    display: block;
    width: 9px;
    height: 9px;
    margin: 5px 5px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    background: #AAABAB;
}
.owl-theme .owl-controls .owl-page.active span {
    border: solid 1px #000;
    padding: 7px;
    background: inherit;
    margin: 1px 5px;
}
.owl-theme .owl-controls .owl-page.active span:before {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: #000;
}


.owl-theme .owl-controls.clickable .owl-page:hover span {

}

/* If PaginationNumbers is true */

.owl-theme .owl-controls .owl-page span.owl-numbers{
    height: auto;
    width: auto;
    color: #FFF;
    padding: 2px 10px;
    font-size: 12px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}

/* preloading images */
/*.owl-item.loading {*/
    /*min-height: 150px;*/
    /*background: url(AjaxLoader.gif) no-repeat center center*/
/*}*/



.owl-theme .owl-controls .owl-buttons .owl-prev,
.owl-theme .owl-controls .owl-buttons .owl-next {
    position: absolute;
    top: 50%;
    height: 22px;
    margin-top: -50px;
    padding: 0;
    font-size: 22px;
    color: #000;
    background-color: inherit;
    filter: Alpha(Opacity=0.5);/*IE7 fix*/
    opacity: 0.5;
}
.owl-theme .owl-controls .owl-buttons .owl-prev {
    left: 0px;
}
.owl-theme .owl-controls .owl-buttons .owl-next{
    right: 0px;
}

.owl-generico,
#owl-recursos,
#owl-test,
#owl-test-pendientes,
#owl-test-realizados,
#owl-subtematicas {
    padding: 0 25px;
}

@media (min-width: 370px) {

    .owl-generico,
    #owl-recursos,
    #owl-test,
    #owl-test-pendientes,
    #owl-test-realizados,
    #owl-subtematicas {
        padding: 0 35px;
    }
    .owl-theme .owl-controls .owl-buttons .owl-prev {
        left: 10px;
    }
    .owl-theme .owl-controls .owl-buttons .owl-next{
        right: 10px;
    }
}
@media (min-width: 430px) {

    #owl-profesores,
    #owl-profesores2,
    .owl-cursos,
    #owl-cursos2,
    #owl-recursos,
    #owl-test,
    #owl-test-pendientes,
    #owl-test-realizados,
    #owl-subtematicas {
        padding: 0 65px;
    }
    .owl-theme .owl-controls .owl-buttons .owl-prev {
        left: 35px;
    }
    .owl-theme .owl-controls .owl-buttons .owl-next{
        right: 35px;
    }
}
@media (min-width: 490px) {

    #owl-profesores,
    #owl-profesores2,
    .owl-generico,
    #owl-recursos,
    #owl-test,
    #owl-test-pendientes,
    #owl-test-realizados,
    #owl-subtematicas {
        padding: 0 95px;
    }
    .owl-theme .owl-controls .owl-buttons .owl-prev {
        left: 55px;
    }
    .owl-theme .owl-controls .owl-buttons .owl-next{
        right: 55px;
    }
}

@media (min-width: 590px) {

    #owl-profesores,
    #owl-profesores2,
    .owl-cursos,
    #owl-cursos2,
    #owl-recursos,
    #owl-test,
    #owl-test-pendientes,
    #owl-test-realizados,
    #owl-subtematicas {
        padding: 0 35px;
    }
    .owl-theme .owl-controls .owl-buttons .owl-prev {
        left: 10px;
    }
    .owl-theme .owl-controls .owl-buttons .owl-next{
        right: 10px;
    }
}















/*  slider home login */

.slider .owl-theme .item {
    padding: 0;
}
.slider .owl-wrapper-outer {

}
.slider .owl-controls.clickable {

}
.slider .owl-controls {
    margin-top: 0px;
    text-align: center;
    height: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.slider .owl-controls .owl-page.active span {
    border: solid 1px rgb(170, 171, 171);
}
.slider .owl-controls .owl-page.active span:before {
    background-color: rgb(170, 171, 171);
}
.slider .owl-controls .owl-buttons {
    /*display: none;*/
    width: 100%;
    left: 0;
    top: 0;
}



.slider .owl-controls .owl-buttons .owl-prev,
.slider .owl-controls .owl-buttons .owl-next {
    position: absolute;
    top: 50%;
    height: 30px;
    line-height: 30px;
    padding: 0;
    font-size: 22px;
    color: #fff;
    background-color: inherit;
    filter: Alpha(Opacity=1);/*IE7 fix*/
    opacity: 1;
    margin-top: -15px;
}
.slider .owl-controls .owl-buttons .owl-prev {
    left: 10px;
}
.slider .owl-controls .owl-buttons .owl-next{
    right: 10px;
}





