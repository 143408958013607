@charset "UTF-8";
/* BEGIN HEADER */
.mnhght {
  min-height: 70px; }

.cabecera {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 68px;
  height: auto;
  margin: 0;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.02);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.02);
  z-index: 500;
  border: 0; }

.web-modo-edicion, .web-inactiva, .pagina-inactiva {
  background-color: rgba(48, 60, 76, 0.9);
  color: #fff; }

.web-modo-edicion a:not(.btn), .web-inactiva a:not(.btn) {
  text-decoration: underline; }

.web-modo-edicion .btn, .web-inactiva .btn, .pagina-inactiva .btn {
  margin-top: 13px;
  padding: 5px; }

.web-inactiva .pull-left, .web-modo-edicion .pull-left, .pagina-inactiva .pull-left {
  margin: 15px auto; }

.web-inactiva .pull-left {
  width: 90%; }

.web-inactiva, .pagina-inactiva {
  border-bottom: 1px solid #fff; }

.logo img {
  max-height: 57px;
  width: auto; }

#busc_submit {
  position: absolute;
  right: -12px;
  top: 0;
  background-color: transparent;
  background-image: url(../img/zoom.svg);
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 9px center;
  height: 100%;
  border-left: 2px solid #d8d8d8;
  min-width: 0; }

#busc_submit input {
  min-width: 0;
  height: 37px;
  border: none;
  background: none; }

.busc input {
  padding-right: 40px; }

/* END HEADER */
@media (min-width: 800px) {
  .dc li:not(.col_3) {
    width: 25%; }
  .dc li:nth-child(1), .dc li:nth-child(2), .dc li:nth-child(4), .dc li:nth-child(5) {
    border-right: solid 1px #DFE1E1; }
  .dc li.col_3 {
    width: 33.33%; } }

@media (max-width: 800px) {
  .dc li {
    width: 50%; } }

.sold_aut {
  padding: 0 !important; }

.etiqueta_curso {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 2; }

.btn-white-border {
  border: #000 solid 1px;
  min-width: 210px !important;
  background-color: #FFF !important; }

.filtro_avanzado2 {
  width: 100%;
  border-top: solid 1px #F2F4F4;
  padding-top: 15px;
  overflow: hidden; }

.filtro_avanzado3 {
  width: 100%;
  overflow: hidden; }

#filtro_rapido.form_activo .filtro_avanzado2 {
  display: block; }

#filtro_avanzado {
  display: none; }

#filtro_avanzado.form_activo {
  display: block; }

#filtro_aplicad {
  display: none; }

#filtro_aplicado.form_activo {
  display: block; }

.filtro input[type=checkbox] {
  display: none; }

.filtro label {
  position: relative;
  font-family: "robotoregular", Arial, Helvetica, sans-serif;
  font-weight: normal;
  height: 34px;
  display: table;
  padding-left: 20px;
  margin: 0; }

.filtro label p {
  display: table-cell;
  vertical-align: middle;
  line-height: 16px; }

.filtro label:before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #f6f8f8;
  border: solid 1px #dfe1e1;
  border-radius: 3px;
  left: 0;
  top: 50%;
  margin-top: -8px; }

@media (max-width: 800px) {
  .tematicas ul li .tem_img {
    /*display: block;*/
    width: 40px;
    height: 40px;
    background-color: #EF3724;
    float: left;
    margin: -5px 0px 10px 75px; }
  .tematicas ul {
    font-family: 'robotomedium', Arial, Helvetica, sans-serif;
    font-size: 15px;
    /*margin: 40px auto 60px 40px;*/
    overflow: hidden;
    margin-top: 25px; }
  .tematicas ul li {
    height: 30px;
    line-height: 30px;
    margin-bottom: 30px;
    padding-bottom: 20px;
    margin-right: 7%;
    padding-top: 5px; } }

@media (min-width: 800px) {
  .tematicas ul li .tem_img {
    display: block;
    width: 90px;
    height: 90px;
    background-color: #EF3724;
    margin: 0 auto 20px;
    /*border-radius: 45px;*/ } }

.filtro input[type=checkbox]:checked + label {
  color: #000; }

.filtro input[type=checkbox]:checked + label:before {
  position: absolute;
  background-repeat: no-repeat;
  background-position: 1px 2px;
  left: 0;
  top: 50%;
  margin-top: -8px; }

.filtro_avanzado2 {
  width: 100%;
  border-top: solid 1px #F2F4F4;
  padding-top: 15px;
  overflow: hidden; }

.filtro_avanzado3 {
  width: 100%;
  overflow: hidden; }

.filtro_avanzado2 li, .filtro_avanzado3 li {
  font-size: 13px;
  float: left;
  padding-right: 17px; }

.filtro_avanzado2 li {
  font-size: 13px;
  float: left;
  padding-right: 17px; }

.filtro_avanzado {
  padding: 15px 11px;
  font-size: 13px;
  color: #6C7076; }

.titulo_filtro {
  font-family: "robotobold", Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #000;
  margin-bottom: 0; }
  .titulo_filtro a {
    display: block; }
    .titulo_filtro a:hover, .titulo_filtro a:focus, .titulo_filtro a:active {
      color: #000; }
  .titulo_filtro span {
    float: right; }
  .titulo_filtro i {
    font-size: 14px; }

.desplegable_filtro_avanzado {
  display: none;
  margin-top: 25px; }

.alineado {
  padding-left: 5px;
  padding-right: 5px; }

.filtro_avanzado .linea {
  margin: 20px 5px 28px 5px; }

.filtro_avanzado li {
  min-height: 36px; }

#desplegables {
  padding-top: 6px; }

.filtro_avanzado li ul {
  display: none;
  float: left;
  width: 100%;
  margin: 8px 0 20px 0;
  width: 100%; }
  .filtro_avanzado li ul li {
    font-size: 13px;
    color: #6C7076;
    margin-left: -5px; }

.filtro_avanzado li label a {
  position: absolute;
  left: 110px;
  top: 1px;
  color: #fff;
  background-color: #A5A5A7;
  border-radius: 50%;
  float: right;
  width: 20px;
  height: 20px;
  line-height: 19px;
  margin-top: 7px;
  margin-right: 130px;
  text-align: center; }

.filtro_avanzado input[type=checkbox] {
  display: none; }

.filtro_avanzado label {
  position: relative;
  font-family: "robotoregular", Arial, Helvetica, sans-serif;
  font-weight: normal;
  width: 100%;
  height: 36px;
  display: table;
  padding-left: 25px;
  border: solid 1px #fff; }
  .filtro_avanzado label p {
    display: table-cell;
    vertical-align: middle;
    line-height: 16px; }
  .filtro_avanzado label:before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: #f6f8f8;
    border: solid 1px #dfe1e1;
    border-radius: 3px;
    left: 5px;
    top: 50%;
    margin-top: -8px; }

.filtro_avanzado input[type=checkbox]:checked + label {
  color: #000;
  background-color: #FEF5F4;
  border: solid 1px #FDD9D4; }
  .filtro_avanzado input[type=checkbox]:checked + label:before {
    position: absolute;
    background-image: url(../img/check_red.svg);
    background-repeat: no-repeat;
    background-position: 1px 2px;
    left: 5px;
    top: 50%;
    margin-top: -8px; }

.filtro_avanzado .desple {
  font-family: "robotomedium", Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: #2B2D33;
  padding: 0 5px;
  overflow: hidden; }
  .filtro_avanzado .desple span {
    float: right; }
    .filtro_avanzado .desple span:first-child {
      float: left; }
  .filtro_avanzado .desple:hover {
    cursor: pointer; }
  .filtro_avanzado .desple .mas:before {
    font-family: "Nucleo Outline";
    content: "";
    font-size: 15px;
    color: #A5A6A8; }
  .filtro_avanzado .desple .menos:before {
    font-family: "Nucleo Outline";
    content: "";
    font-size: 15px;
    color: #A5A6A8; }

.rango {
  padding: 20px 0 10px 0;
  overflow: hidden; }

.slider-euros-margin-value-min, #slider-horas-margin-value-min {
  float: left;
  width: 16%; }

.slider-euros-margin-value-max, #slider-horas-margin-value-max {
  float: left;
  width: 20%;
  text-align: right; }

.slider-euros, #slider-horas {
  float: left;
  width: 64%;
  margin-top: 7px; }

.euros:after {
  content: "€"; }

.horas:after {
  content: "h"; }

.m-l {
  /*float: left; */
  margin-bottom: 5px; }
  .m-l li {
    /*float: left; */
    margin-bottom: 5px; }
    .m-l li:nth-child(1) {
      line-height: 34px; }
    .m-l li:nth-child(2), .m-l li:nth-child(3) {
      margin-left: 15px; }

.modalidad .selct_busc {
  margin-right: 15px; }
  .modalidad .selct_busc > li {
    margin-left: 15px;
    margin-bottom: 15px;
    min-width: 0; }
    .modalidad .selct_busc > li:nth-child(1) {
      margin-bottom: 0; }
  .modalidad .selct_busc .check_busc {
    margin-bottom: 0;
    display: inline-block; }

.filtro input[type=checkbox] {
  display: none; }

.filtro label {
  position: relative;
  font-family: "robotoregular", Arial, Helvetica, sans-serif;
  font-weight: normal;
  height: 34px;
  display: table;
  padding-left: 20px;
  margin: 0; }
  .filtro label p {
    display: table-cell;
    vertical-align: middle;
    line-height: 16px;
    height: 45px; }
  .filtro label:before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: #f6f8f8;
    border: solid 1px #dfe1e1;
    border-radius: 3px;
    left: 0;
    top: 50%;
    margin-top: -8px; }

.filtro input[type=checkbox]:checked + label {
  color: #000; }
  .filtro input[type=checkbox]:checked + label:before {
    position: absolute;
    background-image: url(../img/check_black.svg);
    background-repeat: no-repeat;
    background-position: 1px 2px;
    left: 0;
    top: 50%;
    margin-top: -8px; }

.filtro_avanzado2 {
  width: 100%;
  border-top: solid 1px #F2F4F4;
  padding-top: 15px;
  overflow: hidden; }

.filtro_avanzado3 {
  width: 100%;
  overflow: hidden; }

.filtro_avanzado2 li, .filtro_avanzado3 li, .filtro_avanzado2 li {
  font-size: 13px;
  float: left;
  padding-right: 17px; }

.tematicas_filtro ul {
  border-bottom: solid 1px #F2F4F4;
  overflow: hidden;
  padding-bottom: 7px;
  margin-bottom: 12px; }
  .tematicas_filtro ul .titul {
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 15px;
    font-family: "robotomedium", Arial, Helvetica, sans-serif;
    width: 100%; }
    .tematicas_filtro ul .titul:first-child {
      margin-top: 0; }
  .tematicas_filtro ul:nth-child(8) {
    border: none; }

.filtro_extras {
  margin-top: 20px;
  margin-bottom: 30px;
  overflow: hidden; }
  .filtro_extras ul {
    padding: 20px 0;
    float: left;
    width: 100%;
    border-bottom: solid 1px #DFE1E1; }
    .filtro_extras ul:nth-child(1) {
      border-top: solid 1px #DFE1E1; }
    .filtro_extras ul li:first-child {
      margin-top: 7px;
      font-size: 14px; }

.gasto {
  margin-top: 8px;
  margin-bottom: 6px;
  width: 260px; }

.mas_comprados {
  float: right;
  width: 200px; }
  .mas_comprados .bootstrap-select > .dropdown-toggle {
    background-color: #fff;
    border-color: #fff; }

.cont_btns {
  text-align: center;
  margin: 20px 0 0 0; }
  .cont_btns a {
    margin: 0 10px 10px 10px;
    width: 100%;
    max-width: 150px; }

.cont_btns2 {
  text-align: center;
  margin: 20px 0 0 0; }
  .cont_btns2 a {
    margin: 0 10px 10px 10px;
    width: 100%;
    max-width: 215px; }

.f_tematicas {
  overflow: hidden;
  margin-left: -12px; }
  .f_tematicas li {
    margin-left: 12px;
    margin-bottom: 12px;
    float: left; }
    .f_tematicas li a {
      background-color: #6D7076;
      font-size: 13px;
      border-radius: 3px;
      color: #fff;
      padding: 4px 12px;
      float: left; }
      .f_tematicas li a:hover, .f_tematicas li a:active {
        color: #fff !important; }
      .f_tematicas li a i {
        margin-left: 4px;
        font-size: 12px; }

.cont_tematicas {
  margin-bottom: 30px;
  overflow: hidden; }

.filtro_extras {
  display: flex; }

.filtro_avanzado3 div ul {
  border: none; }

.filtro_avanzado3 .filtro_extras ul {
  border-top: solid 1px #DFE1E1;
  border-left: none; }
  .filtro_avanzado3 .filtro_extras ul:nth-last-child(2), .filtro_avanzado3 .filtro_extras ul:nth-child(1) {
    border-bottom: solid 1px #DFE1E1; }
  .filtro_avanzado3 .filtro_extras ul:nth-last-child(2) {
    border-left: solid 1px #DFE1E1; }

.filtro_avanzado3 .tematicas_filtro ul {
  border-bottom: solid 1px #F2F4F4; }
  .filtro_avanzado3 .tematicas_filtro ul:nth-last-child(1) {
    border-bottom: none; }

/*---------Fin Buscador cursos --------- */
.btn-black {
  background-color: #000; }

.pdf-negro {
  position: relative; }
  .pdf-negro span {
    padding-left: 35px; }

.pdf-negro::before {
  content: " ";
  font-size: 22px;
  display: inline-block;
  position: absolute;
  -webkit-mask-image: url(../img/pdf.svg);
  mask-image: url(../img/pdf.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 22px;
  mask-size: 22px;
  -webkit-mask-position: center left;
  mask-position: center left;
  background-color: #000000;
  height: 22px;
  width: 30px; }

.registro {
  padding: 20px 15px; }

.btn-white-border p {
  color: #000000; }

.compartir_redes_curso.biblioshare {
  display: grid; }

.compartir_redes_curso p {
  line-height: 40px;
  margin-bottom: 8px; }


.compartir_redes_curso ul li a {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-position: center !important;
  background-size: 20px 20px; }
  .compartir_redes_curso ul li a span {
    display: none; }

/*---------Fin Buscador cursos --------- */
.form-registro .error {
  background: #ff7f7faa; }

.form-registro .error::after {
  border-bottom: 10px solid #ff7f7faa; }

.form-registro select {
  margin-bottom: 15px; }

.form-registro .bootstrap-select {
  max-width: 100%;
  float: none !important; }

.form-registro .dropdown-menu {
  z-index: 99999; }

.form-registro .checkbox-radio-inline {
  display: inline-flex;
  min-height: 45px;
  margin-top: 7px;
  margin-bottom: 3px;
  width: 100%;
  text-align: left;
  cursor: default; }
  .form-registro .checkbox-radio-inline input {
    margin-right: 5px;
    /*margin-top: -8px;*/ }
  .form-registro .checkbox-radio-inline label {
    margin-right: 15px; }

#ott_usuarios_aceptoPoliticas > input[type=checkbox] {
  margin: 11px 5px 0 0;
  line-height: normal; }

/*---------Fin Adaptación formulario registro --------- */
/* BEGIN FORMULARIO VALORACION */
.container_valoracion form > p {
  color: #000;
  font-weight: bold; }
  .container_valoracion form > p:last-of-type {
    font-weight: normal; }

/* END FORMULARIO VALORACION */
/* BEGIN FOOTER */
.menu-footer-azul {
  width: 100%; }

.logo-footer {
  max-width: 100%;
  max-height: 37px;
  margin-bottom: 30px;
  width: auto; }

.newsletter-redes-empresa h4 {
  font-family: 'roboto_condensedregular', Arial, Helvetica, sans-serif;
  line-height: 20px;
  margin-bottom: 8px;
  margin-right: 8px;
  text-transform: uppercase; }

/* END FOOTER */
/* BLOQUE SECUNDARIO CURSO */
.calendar-black:before {
  position: absolute;
  font-family: 'Nucleo Outline';
  content: "\e669";
  color: #000000;
  left: 0;
  top: -4px;
  font-size: 22px; }

.direc-black:before {
  position: absolute;
  font-family: 'Nucleo Outline';
  content: "\e880";
  color: #000000;
  left: 0;
  top: -4px;
  font-size: 22px; }

/* ALERT, USADO TAMBIÉN EN MATERIALES DE UN CURSO EN LA FICHA CURSO */
.alert {
  border: 0;
  border-radius: 0;
  padding: 20px; }

.alert-warning {
  color: #000;
  background-color: #F7F7F7; }

/* Gris de 'Soy nuevo'*/
.unete li {
  color: #2B2C33; }

.btn-grey,
.btn-grey:hover,
.btn-grey:focus,
.btn-grey:active {
  color: #ffffff;
  background-color: #2B2C33;
  border-color: #2B2C33; }

.check_add_favoritos_biblio label::before {
  position: absolute;
  content: "";
  display: inline-block;
  background-image: url(../img/favourite_gray.svg);
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  left: 15px;
  top: 50%;
  margin-top: -12px; }

.codigo_descuento input {
  min-height: 44px; }

.font-size-10 {
  font-size: 10px; }

.cursive {
  font-style: italic; }

/*      Home sin login slider  */
#owl-slider-home .item {
  text-align: left;
  color: inherit;
  padding-top: 10%; }

@media (min-width: 1200px) {
  #owl-slider-testimoniales .item {
    height: 450px; }
  #owl-slider-testimoniales .item .txt {
    width: 590px; } }

@media (min-width: 992px) {
  #owl-slider-testimoniales .item {
    height: 419px; } }

@media (min-width: 768px) {
  #owl-slider-testimoniales .item {
    height: 450px;
    color: #fff;
    text-align: center; }
  #owl-slider-testimoniales .item .text label::after {
    bottom: -20px;
    left: 50%;
    content: " ";
    height: 0px;
    width: 0px;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 10px;
    margin-left: -5px; }
  #owl-slider-testimoniales .item .txt {
    position: relative;
    width: 570px;
    margin: 0 auto 15px auto;
    color: #2B2D33;
    background-color: #fff;
    padding: 35px;
    font-family: 'robotolight', Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 26px;
    border-radius: 4px; } }

#owl-slider-testimoniales .item .text {
  font-family: "Open Sans", "Sans-Serif"; }

#owl-slider-testimoniales .item {
  padding-top: 0px !important; }

#owl-slider-testimoniales .owl-wrapper-outer {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(../img/Pic_Users_Home_Color.jpg); }

/* FIN home sin login slides */
.p40 {
  padding: 40px; }

.que_nos_une > li {
  list-style: none; }

.font-auto, .telf {
  color: #000000 !important; }

.btn-black-auto,
.btn-black-auto label:hover,
.btn-black-auto label:focus,
.btn-black-auto label:active {
  color: #ffffff !important;
  background-color: #000000;
  border-color: #000000; }

.btn-red-auto,
.btn-red-auto label:hover,
.btn-red-auto label:focus,
.btn-red-auto label:active {
  color: #ffffff !important;
  background-color: #ef3724;
  border-color: #ef3724; }

.modulo-profesores, .valoracion {
  width: 100% !important; }

.f-profesores {
  background-image: url(../img/cabecera_formadores_v2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff; }

.redes > img {
  width: 40px; }

ul.dc
li:last-child {
  border-right: none !important; }

.centrado_update {
  width: 100% !important;
  max-width: 260px !important;
  text-align: center; }

.redes-sociales {
  position: absolute;
  width: 50%;
  margin-left: 25%;
  padding-left: 10%; }

.facebook-bloqueo {
  content: " ";
  font-size: 22px;
  display: inline-block;
  -webkit-mask-image: url(../img/social_facebook.svg);
  mask-image: url(../img/social_facebook.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center left;
  mask-position: center left;
  color: #000000;
  background-color: #ffffff; }

.circulo-redes {
  background-color: #ffffff;
  border-radius: 50%;
  width: 30px;
  height: 30px; }

.texto-slider {
  margin: 0 0 10px;
  font-size: 16px;
  line-height: 1.42857143;
  width: 100%; }

.cookies .alert {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 !important;
  z-index: 997;
  border-radius: 0;
  background: #000000;
  transition: all 100ms ease-out;
  color: #fff;
  opacity: 1;
  transform: translateY(0%);
  transition-delay: 1000ms; }

.cookies {
  font-size: 17px !important; }

.condiciones_pago input[type=checkbox] {
  /*display: none;*/ }

.condiciones_pago {
  font-size: 14px !important; }

.condiciones_pago label:before {
  /*margin-left: 30px;*/
  position: relative;
  margin-right: 10px;
  font-family: 'Nucleo Outline';
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  background-color: #f6f8f8;
  border: solid 1px #dfe1e1;
  border-radius: 3px;
  vertical-align: top;
  left: 0; }

.condiciones_pago label {
  font-family: 'robotolight', Arial, Helvetica, sans-serif;
  font-weight: normal;
  line-height: 22px;
  height: 22px;
  padding-left: 10px;
  position: relative; }

/*Logo movil*/
.mobile2, .telf > img {
  /*background-image: url(../img/mobile.svg);*/
  -webkit-mask-image: url(../img/mobile.svg) no-repeat 50% 50%;
  mask-image: url(../img/mobile.svg) no-repeat 50% 50%;
  -webkit-filter: saturate(8000%) grayscale(150%);
  filter: saturate(8000%) grayscale(150%); }

/* Intranet autoprovisionada */
.container_home .row_login {
  max-width: 370px;
  background-color: #fff;
  margin: 0 auto;
  padding: 40px; }

.container_home .nav-login {
  padding: 0 !important; }

.container_home .logo2 {
  margin-bottom: 40px; }

.container_home .logo2 img {
  width: 100%; }

.img_home {
  display: none; }

@media (min-width: 992px) {
  .container_home {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .container_home .row_login {
    max-width: 410px;
    width: 410px;
    padding: 60px;
    margin: 0; }
  .container_home .logo2 {
    margin-bottom: 60px; }
  .img_home {
    display: inline-block; }
  .img_home {
    width: calc(100% - 410px); }
  .img_home img {
    width: 100%; } }

@media (min-width: 1200px) {
  .container_home .row_login {
    max-width: 530px;
    width: 530px;
    padding: 80px; }
  .container_home .logo2 {
    margin-bottom: 80px; }
  .img_home {
    width: calc(100% - 530px); } }

@media (min-width: 768px) {
  .nav-login {
    padding: 20px 15px 0 15px; } }

/*Favoritos*/
.check_add_favoritos input[type=checkbox]:checked + label:before {
  background-image: none;
  background-repeat: no-repeat;
  left: 0;
  top: 50%;
  left: 8px;
  margin-top: -12px;
  -webkit-mask-image: url(../img/favourite.svg);
  mask-image: url(../img/favourite.svg);
  height: 23px; }

.shopping_favorites_shake.lleno {
  background-image: none;
  background-repeat: no-repeat;
  width: 24px;
  position: absolute;
  margin: auto;
  left: 38%;
  top: 30%;
  background-size: 100% 100%;
  -webkit-mask-image: url(../img/favourite.svg);
  mask-image: url(../img/favourite.svg);
  height: 23px; }
  @media (max-width: 768px) {
    .shopping_favorites_shake.lleno {
      width: 33%; } }
.modulo-curso .favorito input[type=checkbox]:checked + label:before {
  position: absolute;
  background-image: url(../img/favourite.svg) !important;
  background-repeat: no-repeat;
  top: 50%;
  left: 50%;
  width: 30% !important;
  margin-top: -12px;
  margin-left: -12px;
  height: 23px; }

/*Pagina no disponible redes */
.circulo-redes-facebook {
  background-image: url(../img/social_facebook.svg);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  -webkit-mask-image: url(../img/social_facebook.svg) !important;
  mask-image: url(../img/social_facebook.svg) !important;
  color: #ffffff; }

.f-profesores {
  background-image: none !important; }

.ficha_biblio .autor {
  font-size: 15px;
  padding: 0px; }

.f-white {
  background-color: #ffffff !important; }

.ml10 {
  margin-left: 10px; }

@media (min-width: 1200px) {
  .mi_cuenta_section_1, .mi_cuenta_section_1_2 {
    width: 45%; }
  .mi_cuenta_section_2, .mi_cuenta_section_2_2 {
    width: 32%; }
  .banners_datos_4_1 li {
    width: 33%; } }

.guardar_cambios {
  max-width: 300px; }

.assist {
  margin-left: -1px !important; }

.margenes_f_c {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px; }

/* Mi cuenta */
.mi_cuenta_section_3 {
  color: black; }

.f-perfil .btn-group,
.f-perfil .dropdown-toggle {
  max-width: 310px; }

.cont_mi_cuenta_sections > .mi_cuenta_section_3 > p {
  margin: 0px; }

article.modulo-curso footer p {
  margin: 0px; }

.email {
  position: relative; }

.email span {
  padding-left: 35px; }

.email::before {
  content: " ";
  font-size: 22px;
  display: inline-block;
  position: absolute;
  -webkit-mask-image: url(../img/email.svg);
  mask-image: url(../img/email.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 22px;
  mask-size: 22px;
  -webkit-mask-position: center left;
  mask-position: center left;
  background-color: #000000;
  height: 22px;
  width: 30px; }

.biblio article figure img {
  max-width: 262px;
  width: auto; }

@media (max-width: 768px) {
  div.logo > a > img {
    max-width: 210px; } }

.full-politics {
  margin: 0;
  width: 100%;
  float: none; }
