a{
    cursor:pointer;
}
.error{
    display:none;
    font-weight: normal;
    font-family: sans-serif;
    color:#2B2C33;
}
.modulo-profesores .img-circle{
    width: 100px;
    height: 100px;
    margin: 20px auto 5px auto;
    background-size: cover;
    background-position: center;
}
.modulo-curso .info-curso .img-circle{
    width: 70px;
    height: 70px;
    margin: 5px auto 5px auto;
    background-size: cover;
    background-position: center;
    background-color:white;
    border:none;
}

.opiniones .img-circle{
    width: 50px;
    height: 50px;
    margin: 5px auto 5px auto;
    background-size: cover;
    background-position: center;
    float: left;
    margin-right: 20px;
}
.profesor .img-circle{
    width: 110px;
    height: 110px;
    margin: 20px auto 5px auto;
    background-size: cover;
    background-position: center;
}
.info-extra .alumnos .img-circle {
    width: 45px;
    height: 45px;
    margin: 1px auto 1px auto;
    background-size: cover;
    background-position: center;
}

.menu-cabecera .img-circle{
    width: 50px;
    height: 50px;
    margin: 8px auto;
    background-size: cover;
    background-position: center;
}

.cabecera_titulo h1{
    padding-left: 50px;
    padding-right: 50px;
}


.borde_circulo {
    position: relative;
}
.borde_circulo:before {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    border: solid 1px #E0E2E2;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin-top: -60px;
    margin-left: -60px;
}

.acepto_politicas{
    display:inline-block;
    position:relative;
}
.acepto_politicas label{
    display:table !important;
    font-size: 12px;
    font-weight: normal;
    text-align: initial;
}
.acepto_politicas input{
    font-family: 'Nucleo Outline';
    content: "";
    float: left;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    margin-top: 4px;
    background-color: #f6f8f8;
    border: solid 1px #dfe1e1;
    border-radius: 3px;
}
.acepto_politicas a {
    text-decoration: underline;
}

.barra_blanca{
    max-width:641px;
    background:white;
}
.barra_interior{
    height: 15px;
    background: #9CC100;
}

@media all and (min-width: 320px) {
    .c-menu--slide-left, .c-menu--slide-right, .c-menu--push-left, .c-menu--push-right {
        width: 275px;
        padding: 20px;
    }
}


/*#filtro_rapido .filtro_avanzado2{*/
    /*display:none;*/
/*}*/
#filtro_rapido.form_activo .filtro_avanzado2{
    display:block;
}

#filtro_avanzado{
    display:none;
}
#filtro_avanzado.form_activo{
    display:block;
}

#filtro_aplicado{
    display:none;
}

#filtro_aplicado.form_activo{
    display:block;
}

.compra .precio {
    width: 107px;
}
.cupon_aplicado{
    font-size: 10px;
    width: 100px;
    display: block;
    position: absolute;
    /*right: 30px;*/
}
.desple-carrito .cupon_aplicado{
    right:auto;
}
.subtematica{
    overflow: hidden;
}

.info_profesor .img-circle{
    width: 110px;
    height: 110px;
    margin: 20px 0 25px 0;
    background-size: cover;
    background-position: center;
    display: inline-flex;
    margin-left: auto;
    margin-right: auto;
}

body.loading, body.loading * {
    pointer-events: none;
    cursor:progress !important;
}

.modulo-test h3{
    min-height:50px;
}
.f_contacto .contacto_inputs{
    position:relative;
}

.anchor {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
}

.f-perfil .error{
    left:50px;
}
.check input[type=radio]:checked + label:before {
    content: "\e66f";
    font-size: 25px;
    color: #2B2E33;
    text-align: center;
    line-height: 23px;
}
.check input[type=radio] {
    display: none;
}
.show-test-score .form-group input[type=email] {
    background-image: url(../img/email.svg);
}
.show-test-score .form-group input {
    background-repeat: no-repeat;
    background-position: 10px center;
    padding-left: 40px;
}
.show-test-score .form-group input {
    color: #2B2D33;
}
.show-test-score .form-group label {
    font-weight: inherit;
    position: relative;
}
.show-test-score .form-group .error {
    display:block;
}
.registro .error {
    display:block;
}

.cookies{
    font-size:13px;
}
.c-menu{
    overflow: hidden;
}
.cont-menu{
    top:59px;
}
.buscador .form-control:focus, .cont-menu .form-control:focus{
    border-color: #C2C2C2;
}
.form-control:focus{
    border-color: #C2C2C2;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(145, 141, 141, 0.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(140, 139, 139, 0.6);
}
.busc {
    background-image: none;
    position: relative;
}
#busc_submit {
    position: absolute;
    right: 0;
    top: 0;
    background-color: rgb(246, 248, 248);
    background-image: url(../img/zoom.svg);
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 9px center;
    height: 100%;
    border-left: 2px solid rgb(216, 216, 216);
}
#busc_submit input {
    width: 38px;
    height: 37px;
    border: none;
    background: none;
}

.cookies{
    width:100%;
}

/* sobreescribe estilo ul li en error de symfony form */
.help-block ul.list-unstyled li {
    padding: 0;
    background-image: none;
}

/* texto negrita boton cerrar sesion */
.cerrar_sesion { color: #000 !important; }

.grafico-barras .datos {
    background-color: #fff;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.grafico-barras text {
    font-size: 0.85em;
}
.grafico-barras .axis path, .grafico-barras .axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
}

/*Calendario*/
.calendario_ficha_programa{
    width: 188px;
    position: relative;
}
/*No se puede seleccionar fechas nuevas en la ficha de programa, las fechas son las que son*/
.calendario_ficha_programa .ui-datepicker-calendar{
    pointer-events:none;
}

/* Reset */
.ui-datepicker,
.ui-datepicker table,
.ui-datepicker tr,
.ui-datepicker td,
.ui-datepicker th {
    margin: 4px 0 0 0;
    padding: 0;
    border: none;
    border-spacing: 0;
}

/* Calendar Wrapper */
.ui-datepicker {
    display: none;
    cursor: default;

    font-size: 14px;


    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    /* border-radius: 3px; */

    /* -webkit-box-shadow: 0px 1px 1px rgba(255,255,255, .1), inset 0px 1px 1px rgb(0,0,0); */
    -moz-box-shadow: 0px 1px 1px rgba(255,255,255, .1), inset 0px 1px 1px rgb(0,0,0);
    /* box-shadow: 0px 1px 1px rgba(255,255,255, .1), inset 0px 1px 1px rgb(0,0,0); */
    border: 2px rgb(242, 244, 244) solid;
    margin: 15px 0 15px 0;
}

/* Calendar Header */
.ui-datepicker-header {
    font-weight:bold;
    position: relative;
    padding: 5px;
    /* border-bottom: 1px solid #d6d6d6; */
    background: rgb(242, 244, 244);
}

.ui-datepicker-title { text-align: center; }

/* Month */
.ui-datepicker-month {
    position: relative;
    color: #565656;
}

/* Year */
.ui-datepicker-year {
    padding-left: 8px;
    color: #a8a8a8;
    display: none;
}

/* Prev Next Month */
.ui-datepicker-prev,
.ui-datepicker-next {
    position: absolute;
    top: 0px;
    padding: 5px;
    cursor: pointer;
}

.ui-datepicker-prev {
    left: 5px;
    padding-left: 0;
}

.ui-datepicker-next {
    right: 5px;
    padding-right: 0;
}

.ui-datepicker-prev span,
.ui-datepicker-next span{
    display: block;
    width: 13px;
    height: 22px;
    /* text-indent: -9999px; */

    /* background: white; */
    color: rgb(168, 168, 168);
}

.ui-datepicker-prev span { background-position: 0px 0px; }

.ui-datepicker-prev-hover span { background-position: 0px -10px; }

.ui-datepicker-next-hover span { background-position: -5px -10px; }

/* Calendar "Days" */
.ui-datepicker-calendar th {
    padding-top: 15px;
    padding-bottom: 10px;

    text-align: center;
    font-weight: normal;
    color: #a8a8a8;
    display: none;
}

.ui-datepicker-calendar td {
    /* padding: 0 7px; */

    text-align: center;
    line-height: 26px;
}

.ui-datepicker-calendar .ui-state-default {
    display: block;
    width: 26px;
    outline: none;

    text-decoration: none;
    color: #a8a8a8;

    border: 1px solid transparent;
}

/* Day Active State*/
.ui-state-highlight .ui-state-default{
    color: white;
    background: rgb(239, 55, 36);
    border-radius: 50%;
}

/* Other Months Days*/
.ui-datepicker-other-month .ui-state-default { color: #DDDDDD; }
/*fin Calendario*/

#Modal_eliminar{
    display:none;
    padding-right: 15px;
}

.desple-carrito .docente {
    color:black;
}

.rojo{
    color: #ef3724;
}

.mensaje_enviado form{
    display:none;
}
.gracias_mensaje_enviado{
    display:none;
}
.mensaje_enviado .gracias_mensaje_enviado {
    display: block;
    margin-top: 65px;
}
.mensaje_enviado .gracias_mensaje_enviado p{
    margin-top: 15px;

}


.tambien_disponible{
    text-align: center;
    padding: 20px;
    background: white;
    padding-bottom: 0;
}

.tambien_disponible>div{
    border-bottom: 1px dashed #6D7076;;
}

.ficha_curso .tambien_disponible>div{
    border-bottom:none;
}

.buscador .form-control, .cont-menu .form-control{
    height:100%;
}

.cont_btn_formacion {
    background-color: #F2F4F4;
    padding: 13px 20px;
}

@media (min-width: 768px){
    .cont_btn_f {
        float: right;
        padding: 14px 0 0 28px;
    }
}

.cabecera{
    z-index: 500;
}

.centrado{
    text-align: center;
}

.recupera{
    background-color: #fff;
    padding: 20px;
}

.recupera form{
    width: 400px;
    margin: auto;
}

.centrado_update .fs42{
    line-height:42px;
    margin-bottom: 17px;
}

.centrado_update .btn-block{
    margin-top: 23px;
}
.centrado_update p:nth-child(2){
    margin-bottom: 18px;
}

.recuperar_password_enviado{
    display:none;
    margin-bottom: 30px;
    color:green;
}
.recupera .contacto_inputs{
    position:relative;
}
.condiciones_pago{
    overflow: inherit;
}
.condiciones_pago > div{
    position:relative;
}
.f-perfil{
    position:relative;
}

.suscrito.ya_registrado{
    /*background-image: url(../img/mensage_error.svg);*/
}

.sold_aut, .finalizado{
    pointer-events: none;
}

.breadcrumb > .active{
    cursor:pointer;
}

.apuntarme_anyadido div:not(.error){
    font-size: 18px;
    opacity: 0.65;
    cursor:not-allowed;
}

.contacta_enviado{
    display: none;
    color:green;
}

.video_curso a {
    color: #0081D5;
    text-decoration: underline;
}

.como_funciona_bono_f5{
    width:100%;
}

.proximamente{
    pointer-events: none;
}
.avisame_activado .btn-aviso_desactivado, .btn-aviso_activado{
    display:none;
}
.avisame_activado .btn-aviso_activado{
    display:inline-block;
    pointer-events: none;
}
.proximamente .cont-btn{
    pointer-events: all;
}
.mensaje_enviado form{
    display:none;
}

.unete li{
    font-size:15px;
}

.valorar .star{
    z-index:100;
}

.position-relative { position: relative }

.comentario.respuesta:before{
    display:none;
}

.responder_desplegado .respuesta_escondido{
    display:block;
}
.responder_desplegado .mostrar_responder{
    display:none;
}
.respuesta_escondido{
    display:none;
}
.respuesta .comentario:before{
    display: none;

}
.suscripciones_button{
    cursor:default;
}
.alumno .img-circle{
    width: 190px;
    height: 190px;
    margin: 20px auto 5px auto;
    background-size: cover;
    background-position: center;
    border: none;
}
.condiciones_newsletter{
    position:relative;
    margin-left: 0;
}

.otras_ciudades{
    z-index:2;
    max-height: 300px;
    overflow-y: overlay;
    padding-right: 11px;
}

.orden_listado {
    float: right;
    width: 200px;
}
.orden_listado .bootstrap-select > .dropdown-toggle {
    background-color: #fff;
    border-color: #fff;
}

.parte li p{
    max-width:430px;
}
.modulo-curso .img-curso{
    /*filter: gray;
    filter: grayscale(1);
    -webkit-filter: grayscale(1); */
}
.logo{
    z-index: 1;
}

.menu-derecha-xs{
    position:relative;
    z-index:1;
}
#aplicar_bono{
    display: block;
    margin: 0 auto;
}
.codigo_descuento input{
    width: 100%;
}

.menu-desplegable a{
    z-index: 2;
}
@media (max-width: 767px) {
    .c-menu{
        overflow: auto;
    }
    .opiniones .img-circle{
        z-index: 1;
        position: relative;;

        margin: 5px;
    }

    .comentario,.comentario_2{
        min-height: 60px;
    }
    .condiciones_newsletter{
        margin-top:20px;
    }

}
.subtematica li{
    list-style: none;
    text-decoration: underline;
}

.curso_programa.opacidad{
    opacity: 0.5;
}

#busq_profesores_ciudad .busc{
    margin-top: 0px;
    height: 45px;
    width:100%;
}
#busq_profesores_ciudad .buscador{
    border: 0;float: right;width:100%;
}

#busq_profesores_ciudad{
    display:flex;
}
.modalidad{
    width:103%;
}

.equipo_profesor .img-circle{
    width: 93px;
    height: 93px;
    margin: 20px auto 5px auto;
    background-size: cover;
    background-position: center;
}

.equipo_profesor .borde_circulo:before{
    width: 101px;

    height: 101px;
    margin-top: -51px;
    margin-left: -50px;
}

.equipo_profesor{
    height: 395px;
    position: relative;
}
.equipo_redes{
    position: absolute;

    width: 100%;
    left: 0;
    bottom: 25px;
}

.apuntarme_anyadido{
    pointer-events: none;
}
.cabecera .notificacion, .cabecera .notificacion-xs{

}

.cabecera .img-usuario-xs{
    float: none;
    margin-left: 10px;
}

.sin_eventos{
    pointer-events: none;
}
.cerrar_cookies{
    top:20px;
}
.colum-newsletter h4{
    letter-spacing: 0.3px;
}
.colum-newsletter{
    display: inline-block;
    margin-bottom: 12px;
}
.cont-redes {
    margin-bottom: 29px;
}
.metodos-pago {
    margin-top: 54px;
}
.ebook_info .error{
    margin-left: 100px;
}

.titul_ebook2{
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}
.ficha_curso .apuntarme, .ficha_curso .precio_curso span {
    background-color: white;

    color: black;
}

.ficha_curso .apuntarme .star{
    background-color:white;
}
.ficha_curso .linea_fondo,.ficha_curso .precio_curso {
    background-image: url(../img/punto_negro.gif);
}

.tab-content .container{
    width: inherit;
    padding: 0;
}
.ficha_curso .apuntarme {
    padding: 0;
}
#datos .box > div{
    position: relative;
}
.titulo_6{
    /*width: 400px;*/
    margin-left: auto;
    margin-right: auto;
}
.iframe_resumenes{
    width: 100%;
    height: 2000px;
    border: none;
    overflow: overlay;
}
.checkbox_politica{
    position: relative;
}

.descripcion_curso strong{
    font-family: 'robotomedium', Arial, Helvetica, sans-serif;
    font-weight: normal;
}

.desple-notificaciones-vacio{
    padding:0;
}
.comprado_no_finalizado{
    pointer-events: none;
    background: none;
}
.curso_programa .btn{
    pointer-events: all;
    cursor:pointer;
}
.modulo-curso > div:first-child{
    position:relative;
}
.modulo-curso{
    position: relative;
}
.btn_disabled{
    opacity:0.6;
    cursor:not-allowed;
}

@media (min-width: 768px) {
    .shopping_favorites_shake.lleno {
        background-image: url(../img/favourite.svg);
        background-repeat: no-repeat;
        height: 35%;
        width: 28%;
        position: absolute;
        margin: auto;
        left: 38%;
        top: 30%;
        background-size: 100% 100%;
    }

    .shopping_favorites_shake.lleno i {
        display: none;
    }
}